<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="等级" prop="agentTeamLevelId">
          <a-select style="width: 120px" placeholder="请选择" v-model='queryRef.agentTeamLevelId'>
            <a-select-option :value="item.agentTeamLevelId" :key='item.agentTeamLevelId + index' v-for='(item,index) in levelList'>
              {{item.levelName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="上级" prop="upAgentName">
          <a-input v-model="queryRef.upAgentName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="加入时间" prop="dateRange">
          <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class='info-box'>
      <div class='info-item' v-for='(item,index) in infoData' :key='index'>{{item.name}}：{{item.value}}</div>
    </div>
    <a-tabs v-model='queryRef.type' @change="changeTab">
      <a-tab-pane :key="0" tab="我的团队">
      </a-tab-pane>
      <a-tab-pane :key="1" tab="我的客户">
      </a-tab-pane>
    </a-tabs>

    <a-table :rowKey="(record,index) => record.userId" :data-source="tableData" :loading='loading' :columns="columns" :pagination="pagination" @change='tableChange'>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click='editRecord(record)'>修改等级</a-button>
      </div>
    </a-table>
    <a-modal v-model="showModal" title='修改等级' :confirm-loading="confirmLoading" @ok='submit' @cancel='cancel'>
      <a-form-model ref="modalFormRef" :model="form" :rules="rules" :label-col="{span:4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="选择等级" prop="agentTeamLevelId">
          <a-select style="width: 100%" placeholder="请选择" v-model='form.agentTeamLevelId'>
            <a-select-option :disabled="currentRecord.agentTeamLevelId === item.agentTeamLevelId" :value="item.agentTeamLevelId" :key='item.levelList + index' v-for='(item,index) in levelList'>
              {{item.levelName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { agent } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'PageTeamManagement',
  data: () => ({
    loading: false,
    queryRef: {
      type: 0,
      agentTeamLevelId: undefined,
      dateRange: [],
    },
    showModal: false,
    infoData: null,
    pagination: {
      current: 1,
      size: 'small',
      pageSize: 10,
      total: 0,
    },
    rules: {
      agentTeamLevelId: [{ required: true, message: '请选择' }]
    },
    form: {},
    currentRecord: {},
    confirmLoading: false,
    tableData: [],
    levelList: [],
    usersByLevel: [],
    selectLoading: false,
    columns: [
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
      },
      {
        title: '代理等级',
        dataIndex: 'levelName',
        scopedSlots: { customRender: 'levelName' },
      },
      {
        title: '上级代理',
        dataIndex: 'upAgentName',
        scopedSlots: { customRender: 'upAgentName' },
      },
      {
        title: '累计收益',
        dataIndex: 'totalAmount',
      },
      {
        title: '可提现收益',
        dataIndex: 'cashAmount',
      },
      {
        title: '余额',
        dataIndex: 'balanceAmount',
      },
      {
        title: '加入时间',
        dataIndex: 'joinTime',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ],
  }),
  mounted () {
    this.getLevelList()
    this.getInfo()
    this.getData()
  },
  methods: {
    changeTab (e) {
      this.queryRef.type = e
      this.pagination.current = 1
      this.getData()
    },
    async getInfo () {
      const { data, code } = await agent.getTeamInfoData()
      if (code === '00000') {
        this.infoData = data
      }
    },
    async getLevelList () {
      const { data, code } = await agent.getTeamLevelList()
      if (code === '00000') {
        this.levelList = data
      }
    },
    async getData () {
      this.loading = true
      const { data, code, msg } = await agent.getTeamPage({
        ...this.queryRef,
        joinBeginTime:
          this.queryRef.dateRange && this.queryRef.dateRange.length
            ? this.queryRef.dateRange[0]
            : '',
        joinEndTime:
          this.queryRef.dateRange && this.queryRef.dateRange.length
            ? this.queryRef.dateRange[1]
            : '',
        current: this.pagination.current,
        size: this.pagination.pageSize,
      })
      this.loading = false
      if (code === '00000') {
        this.tableData = data
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    handleSearch () {
      this.pagination.current = 1
      this.getData()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getData()
    },
    tableChange ({ current }) {
      this.pagination.current = current
      this.getData()
    },
    editRecord (record) {
      this.currentRecord = Object.assign({}, record)
      this.showModal = true
    },
    // async changeSelectLevel (e) {
    //   console.log(e)
    //   this.selectLoading = true
    //   let { data, msg, code } = await agent.usersByLevel({
    //     preAgentTeamLevelId: this.currentRecord.agentTeamLevelId,
    //     afterAgentTeamLevelId: e,
    //     userId: this.currentRecord.userId
    //   })
    //   this.selectLoading = false
    //   if (code === '00000') {
    //     if (!data || !data.length) {
    //       data = [{
    //         realName: '暂无可选上级',
    //         userId: ''
    //       }]
    //     }
    //     this.usersByLevel = data
    //   }
    // },
    async  submit () {
      this.confirmLoading = true
      const { code, msg } = await agent.changeUserLevel({
        userId: this.currentRecord.userId,
        shopId: getSession(SHOPID),
        agentTeamLevelId: this.form.agentTeamLevelId
      })
      this.confirmLoading = false
      if (code === '00000') {
        this.showModal = false
        this.handleSearch()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    cancel () {
      this.showModal = false
      this.$refs.modalFormRef.resetFields()
    },
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: flex;
  align-items: center;
  margin: 12px;
  .info-item {
    margin-right: 48px;
  }
}
</style>
